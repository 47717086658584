import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import PosterSubCat from './components/categoryPages/Poster/PosterSubCat';
// import SignagesSubCat from './components/categoryPages/Signages/SignagesSubCat';
// import ProductSpec from './components/commonComponents/productSpecs/ProductSpec';
// import SignIn from './components/userAuthComp/SignIn';
// import AboutUs from './Pages/staticPages/AboutUs';
// import CartPage from './Pages/CartPage';
// import Home from './Pages/Home';
// import Poster from './Pages/poster';
// import Signages from './Pages/Signages';
// import FloorGraphics from './Pages/FloorGraphics';
// import AssetMarkings from './Pages/AssetMarkings';
// import UserAuth from './Pages/UserAuth';
// import PosterSpec from './components/commonComponents/productSpecs/PosterSpec';
// import Specification from './components/commonComponents/productSpecs/Specification';
// import AssetSpec from './components/commonComponents/productSpecs/AssetSpec';
// import UserDashboard from './components/userDashboard/UserDashboard';
// import ThankYou from './Pages/staticPages/ThankYou';
// import Checkout from './Pages/staticPages/Checkout';
// import PlaceOrder from './components/cartComponents/PlaceOrder';
// import Contact from './Pages/staticPages/Contact.jsx';
// import BulkOrder from './Pages/staticPages/BulkOrder';
// import Navbar from './components/commonComponents/Navbar/Navbar';
import Signoutconfirmation from "./Pages/staticPages/Signoutconfirmation";
import Error from "./Pages/staticPages/Error";
import Footer from "./components/commonComponents/Footer/Footer";
import TermsCondition from "./Pages/staticPages/TermsCondition";
import PrivacyPolicy from "./Pages/staticPages/PrivacyPolicy";
import Faqs from "./Pages/staticPages/Faqs.jsx";
import Loading from "./components/commonComponents/LoadingScreen/Loading";
import Review from "./components/commonComponents/popUp/Review";
import Payment from "./components/cartComponents/Payment";
import PdfMaker from "./components/userDashboard/pdf/PdfMaker";
import ResourcesAndCampaigns from "./Pages/ResourcesAndCampaigns";
import { useLocation } from "react-router-dom";
import NotSignInPopUp from "./components/commonComponents/Crads/NotSignInPopUp";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import ForgetPassword from "./components/userAuthComp/ForgetPassword";
import { Toaster } from "react-hot-toast";
import SearchedProducts from "./components/commonComponents/SearchedProducts/SearchedProducts";
// import TrackYourOrder from "./components/TrackOrder/TrackYourOrder";
// import EditProfile from "./components/userDashboard/dashboardComponents/EditProfile";
// import Loader from "./components/commonComponents/Loader/Loader";
// import TrackOrder from "./components/TrackOrder/TrackOrder";
// import SubCategory from "./components/categoryPages/SubCategory";
// import SubCategoryItems from "./components/categoryPages/SubCategoryItems";
// import AppDiy from "./components/doityourself/src/AppDiy";
// import Order from "./components/doityourself/src/components/Pages/Order";
// import NavbarDiy from "./components/doityourself/src/components/DoItYourSelf/Commons/Navbar";
// import RefunAndPolicy from "./Pages/staticPages/RefunAndPolicy";
// import MobileViewComponent from "./MobileViewComponent";
// import ComingSoonComp from "./components/commonComponents/ComingSoonComp/ComingSoonComp";
// import VerifyOtp from "./components/userAuthComp/VerifyOtp";
// import JoinUs from "./components/commonComponents/JoinUs/JoinUs";
// import ArtistForm from "./components/commonComponents/JoinUs/Forms/ArtistForm";
// import PrintersForm from "./components/commonComponents/JoinUs/Forms/PrintersForm";
// import EmployeeForm from "./components/commonComponents/JoinUs/Forms/EmployeeForm";
// import FormSubmitThankyou from './components/commonComponents/JoinUs/FormSubmitThankyou';
import { Check } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "./localStorage";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API } from "./backend";
import { toast } from "react-hot-toast";
import closeIcon from "./images/icons/closeIcon.svg";
import { getUser } from "../src/redux/actions/userAction.js";
// import { getUser } from "./redux/actions/userAction.js";
// import LandingPage from "./Pages/LandingPage";
// import CorporateLogin from "./components/userAuthComp/CorporateLogin";
// import CorporateSignup from "./components/userAuthComp/CorporateSignup";
// import VerifyCorporateOtp from "./components/userAuthComp/VerifyCorporateOtp";


export const DesContext = React.createContext({});

const LandingPage = lazy(() => import("./Pages/LandingPage"));
const SignIn = lazy(() => import("./components/userAuthComp/SignIn"));
const AboutUs = lazy(() => import("./Pages/staticPages/AboutUs"));
const CartPage = lazy(() => import("./Pages/CartPage"));
const Home = lazy(() => import("./Pages/Home"));
const UserAuth = lazy(() => import("./Pages/UserAuth"));
const UserDashboard = lazy(() => import("./components/userDashboard/UserDashboard"));
const ThankYou = lazy(() => import("./Pages/staticPages/ThankYou"));
const Checkout = lazy(() => import("./Pages/staticPages/Checkout"));
const PlaceOrder = lazy(() => import("./components/cartComponents/PlaceOrder"));
const Contact = lazy(() => import("./Pages/staticPages/Contact.jsx"));
const BulkOrder = lazy(() => import("./Pages/staticPages/BulkOrder"));
const TrackYourOrder = lazy(() => import("./components/TrackOrder/TrackYourOrder"));
const TrackOrder = lazy(() => import("./components/TrackOrder/TrackOrder"));
const SubCategory = lazy(() => import("./components/categoryPages/SubCategory"));
const CustomizablePosters = lazy(() => import("./components/categoryPages/CustomizablePosters"))
const SubCategoryItems = lazy(() => import("./components/categoryPages/SubCategoryItems"));
const PosterSpec = lazy(() => import("./components/commonComponents/productSpecs/PosterSpec"));
const Specification = lazy(() => import("./components/commonComponents/productSpecs/Specification"));
const AssetSpec = lazy(() => import("./components/commonComponents/productSpecs/AssetSpec"));
const JoinUs = lazy(() => import("./components/commonComponents/JoinUs/JoinUs"));
const ArtistForm = lazy(() => import("./components/commonComponents/JoinUs/Forms/ArtistForm"));
const PrintersForm = lazy(() => import("./components/commonComponents/JoinUs/Forms/PrintersForm"));
const EmployeeForm = lazy(() => import("./components/commonComponents/JoinUs/Forms/EmployeeForm"));
const AppDiy = lazy(() => import("./components/doityourself/src/AppDiy"));
const Navbar = lazy(() => import("./components/commonComponents/Navbar/Navbar"));
const Order = lazy(() => import("./components/doityourself/src/components/Pages/Order"));
// const MobileViewComponent = lazy(() => import("./MobileViewComponent"))
const RefunAndPolicy = lazy(() => import("./Pages/staticPages/RefunAndPolicy"));
const ShippingAndDeliveryPolicy = lazy(() => import("./Pages/staticPages/ShippingandDelivery.jsx"));
const VerifyOtp = lazy(() => import("./components/userAuthComp/VerifyOtp"));
const FormSubmitThankyou = lazy(() => import("./components/commonComponents/JoinUs/FormSubmitThankyou"));

const Campaign = lazy(() => import("./components/commonComponents/Campaign/Campaign.jsx"));
const RoadSafety = lazy(() => import("./components/commonComponents/Campaign/RoadSafety.jsx"));
const Biodiversity = lazy(() => import("./components/commonComponents/Campaign/Biodiversity.jsx"));
const MonsoonSafety = lazy(() => import("./components/commonComponents/Campaign/MonsoonSafety.jsx"));
const OfficeEconomics = lazy(() => import("./components/commonComponents/Campaign/OfficeEconomics.jsx"));
const HandsAndFingersSafety = lazy(() => import("./components/commonComponents/Campaign/HandsAndFingersSafety.jsx"));

// const CampaignDetails = lazy(() => import("./components/commonComponents/Campaign/CampaignDetails.jsx")); ///will be using it later if necessary
const DiyCart = lazy(() => import("./components/doityourself/src/components/Pages/DiyCart"))
// const SearchedProducts = lazy(() => import("./components/commonComponents/SearchedProducts/SearchedProducts"))
// User Diy section 
const UserDiyCheckout = lazy(() => import("../src/components/doityourself/src/components/Pages/Checkout"));
const UserDiyThankyou = lazy(() => import("../src/components/doityourself/src/components/Pages/Thankyou"));
const CorporateLogin = lazy(() => import("./components/userAuthComp/CorporateLogin"))
const CorporateSignup = lazy(() => import("./components/userAuthComp/CorporateSignup"))
const VerifyCorporateOtp = lazy(() => import("./components/userAuthComp/VerifyCorporateOtp"))

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const { handleSubmit, register } = useForm();
  let id = location.pathname.split("/");
  id = id[id.length - 1];

  // console.log("id in appjs", id);
  const user_details = useSelector((state) => state.user.userDetail);
  const dispatch = useDispatch();

  // console.log(location.pathname, '))))))))))))))))))))))');
  // Stopping user visiting website from mobile
  // const [showMobileWarning, setShowMobileWarning] = useState(false);

  // useEffect(() => {
  //   if (window.innerWidth <= 1024) setShowMobileWarning(true);
  // }, []);
  // Stopping user visiting website from mobile

  // return location==="diyhome" ? (<>
  //   <Routes>
  //   <Route path="/diyhome" element={<AppDiy />}>

  //   </Routes>
  //   </>
  // ):(

  useEffect(() => {
    const fetchData = async () => {
      if ((localStorage.getItem("myuser")) && !user_details) {
        // alert("getUser dispatched from in app js")
        const result = await dispatch(getUser());
      }
    };
    fetchData();
  }, [dispatch, user_details]);


  useEffect(() => {
    if (!localStorage.getItem("customDimension")) {
      // alert("app.js");
      localStorage.setItem("customDimension", JSON.stringify({ height: "24", width: "16", uint: "in" }));
    }
    if (!localStorage.getItem("popUp")) {
      // alert("app.js");
      localStorage.setItem("popUp", "enabled");
    }
  }, []);

  const [showPopup, setShowPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const firstTimeUser = getLocalStorageItem("firstTimeUser");

  useEffect(() => {
    const firstTimeUser = getLocalStorageItem("firstTimeUser");
    if (firstTimeUser === null) {
      // Set the "firstTimeUser" flag in local storage if it doesn't exist
      setLocalStorageItem("firstTimeUser", true);
    } else {
      // The user has visited before; don't show the pop-up
      setLocalStorageItem("firstTimeUser", false)
      setShowPopup(false);
    }
  }, []);

  useEffect(() => {
    const handleMouseOut = (event) => {
      // Check if the mouse is moving outside the window (off the website)
      if (event.clientY <= 0 || event.clientX <= 0 || event.clientX >= window.innerWidth || event.clientY >= window.innerHeight) {
        setShowPopup(true);
        setLocalStorageItem("firstTimeUser", false)
      }
    };
    if (firstTimeUser === null || firstTimeUser) {
      // Add event listener to trigger pop-up only when needed
      window.addEventListener("mouseout", handleMouseOut);
    }
    return () => {
      // Clean up the event listener when component unmounts
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, [showPopup, firstTimeUser]);

  const handlePopupData = async (data) => {
    try {
      setIsLoading(true)
      const response = await axios.post(`${API}auth/userNumber`, data)
      if (response.status === 200) {
        setIsLoading(false)
        setShowPopup(false)
        toast.success("Thanks we will connect with you soon.")
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  // disable right-click functionality in a production mode of a web application 
  // For a time being i am going to comment the below code but when all the test phase  will complete ,this should be enable
  //  useEffect(() => {
  //   const currentUrl = window.location.href;

  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };
  //   const disableDevTools = (e) => {
  //     if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 73) {
  //       e.preventDefault();
  //     }
  //   };

  //   if (currentUrl.startsWith("https://ehsprints.com/")) {
  //     document.addEventListener("contextmenu", disableRightClick);
  //     document.addEventListener("keydown", disableDevTools);
  //   }

  //   return () => {
  //     if (currentUrl.startsWith("https://ehsprints.com/")) {
  //       document.removeEventListener("contextmenu", disableRightClick);
  //       document.removeEventListener("keydown", disableDevTools);
  //     }
  //   };
  // }, []);


  return (
    <div className="App">
      {showPopup && (
        <div className=" absolute inset-0 w-[98vw] h-screen bg-[#0000005c] popup__overlay">
          <div
            // ref={popUpRef}
            className="absolute left-[10%] right-[10%] md:left-[25%] md:right-[25%] lg:left-[30%] lg:right-[30%] xl:left-[34%] xl:right-[34%] 2xl:left-[36%] 2xl:right-[36%] top-[25%] h-[340px] md:h-[320px] w-[80vw] md:w-[50vw] lg:w-[40vw] xl:w-[32vw] 2xl:w-[28vw] bg-[#ffffff] shadow-2xl rounded-xl  popup__container z-[99] py-4"
          >
            <div>
              <img src={closeIcon} alt="close" className="absolute w-4 top-2 right-4 cursor-pointer" onClick={() => { setShowPopup(false) }} />
            </div>
            <form onSubmit={handleSubmit(handlePopupData)} className=" flex flex-col justify-center items-center relative mx-auto px-4 md:px-8">
              <h1 className=" text-[#141414] text-xl md:text-3xl text-center">Leaving Early?</h1>
              <p className=" w-[80%] md:w-[70%] text-center text-sm md:text-base">We would love to hear your specific print needs. We think we can help !</p>
              <h3 className=" text-[#141414] text-lg md:text-2xl opacity-90">Request a callback!</h3>
              <input type="text" placeholder="Your number" className="input input-bordered w-full max-w-xs" {...register("number", { required: true })} />
              <p className=" bg-yellow-400 text-xs mx-3 text-center"> No worries! No spam. Our Promise!!!</p>
              <button className="btn-primary rounded px-3 py-1 mt-2 disabled:cursor-not-allowed disabled:opacity-70" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}

      <Suspense fallback={<Loading />}>
        {location.pathname === `/diy/${id}` || location.pathname === "/diyhome" || location.pathname === "/orderdiy" ? null : (<Navbar setIsAdmin={setIsAdmin} />)}
        <Toaster />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/diy/:id" element={<PrivateRoute> <AppDiy setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/diyhome" element={<PrivateRoute> <AppDiy setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/orderdiy" element={<Order />} />
          <Route path="/diy/cartpage" element={<DiyCart />} />
          <Route path="/diy/checkout" element={<UserDiyCheckout />} />
          <Route path="/diy/thankyou" element={<UserDiyThankyou />} />
          <Route path="/ehsprints" element={<LandingPage />} />
          <Route path="/category/:categoryName" element={<SubCategory />} />
          <Route path="/subcategory/customize/posters" element={<CustomizablePosters />} />
          {/* for subcategory component */}
          <Route path="subcategory/:categoryName" element={<SubCategoryItems />} />
          {/* <Route path="/poster" element={<Poster />} /> */}
          {/* <Route path="/poster/postersubcategory" element={<PosterSubCat />} /> */}
          {/* <Route path="/signages" element={<Signages />} /> */}
          {/* <Route  path="/signages/signagessubcategory" element={<SignagesSubCat />} /> */}
          {/* <Route path="/floorgraphics" element={<FloorGraphics />} /> */}
          {/* <Route path="/assetmarkings" element={<AssetMarkings />} /> */}
          <Route path='/campaign' element={<Campaign />} >
          </Route>
          <Route path="/campaign/road-safety" element={<RoadSafety />} />
          <Route path="/campaign/biodiversity" element={<Biodiversity />} />
          <Route path="/campaign/monsoon-safety" element={<MonsoonSafety />} />
          <Route path="/campaign/office-economics" element={<OfficeEconomics />} />
          <Route path="/campaign/hands-and-fingers-safety" element={<HandsAndFingersSafety />} />
          {/* <Route path="/campaign/:campaignName" element={<CampaignDetails />} />  ///will be using later if necessary */}
          <Route path="/cartpage" element={<PrivateRoute> <CartPage /> </PrivateRoute>} />
          <Route path="/cartpage/placeorder" element={<PlaceOrder />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/signup" element={<UserAuth />} />
          <Route path="/vrfotp" element={<VerifyOtp />} />
          <Route path="/verifycorporateotp" element={<VerifyCorporateOtp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/corporatelogin" element={<CorporateLogin />} />
          <Route path="/corporatesignup" element={<CorporateSignup />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/posterspecification/:productid" element={<PosterSpec />} />
          <Route path="/specification/:productid" element={<Specification />} />
          <Route path="/assetspecification/:productid" element={<AssetSpec />} />
          <Route path="/userdashboard/:id" element={<UserDashboard />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/signout" element={<Signoutconfirmation />} />
          <Route path="/resources" element={<ResourcesAndCampaigns />} />
          <Route path="*" element={<Error />} />
          <Route path="/joinus" element={<JoinUs />} />
          {/* join us form routes */}
          <Route path="/joinus/artistform" element={<ArtistForm />} />
          <Route path="/joinus/printersform" element={<PrintersForm />} />
          <Route path="/joinus/employeeform" element={<EmployeeForm />} />
          <Route path="/joinus/formsubmit" element={<FormSubmitThankyou />} />
          {/* join us form routes */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/bulkorder" element={<BulkOrder />} />
          <Route path="/terms" element={<TermsCondition />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/refundpolicy" element={<RefunAndPolicy />} />
          <Route path="/shippinganddeliverypolicy" element={<ShippingAndDeliveryPolicy />} />
          <Route path="/trackyourorder" element={<TrackYourOrder />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/trackorder" element={<TrackOrder />} />
          <Route path="/invoices" element={<PdfMaker />} />
          <Route path="/review" element={<Review />} />
          <Route path="/pay" element={<Payment />} />
          <Route path="/searchedproduct" element={<SearchedProducts />} />
          <Route path="/wishlistpopup" element={<NotSignInPopUp />} />
        </Routes>
        {location.pathname === `/diy/${id}` || location.pathname === "/diyhome" || location.pathname === "/orderdiy" ? null : (<Footer />)}
      </Suspense>
    </div>
  );
}

export default App;
