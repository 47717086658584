import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import plusIcon from "../../../images/icons/plusBgIcon.svg";
import checkBox from "../../../images/icons/checkBox.svg";
import closeIcon from "../../../images/icons/closeIcon.svg";
import {
  editUserBillingAddress,
  updateUserAddressAndWishlist,
} from "../../../redux/actions/userAction";
import { AddressPopup } from "../../commonComponents/popUp/PopUp";

const AddBillingAddress = ({
  setSavedAdd,
  setDeliveryAdd,
  setOrderClick,
  setSelectedAddToggle,
  setConfirmDelivNo,
}) => {
  let userDetails = useSelector((state) => state.user.userDetail);
  let billingAddress = useSelector((state) => state.user.toEditBillingAddress);
  const dispatch = useDispatch();

  const [billingAddressId, setBillingAddressId] = useState("");
  //   const [city, setcity] = useState("");
  //   const [pincode, setPincode] = useState(Number);
  //   const [state, setState] = useState("");
  //   const [country, setCountry] = useState("");
  const [editToggle, setEditToggle] = useState(false);
  const [editBillingToggle, setEditBillingToggle] = useState(false);
  //   const [Area_Street, setArea_Street] = useState("");
  //   const [type, settype] = useState("");
  //   const [name, setname] = useState("");
  //   const [phone, setphone] = useState("");
  //   const [defaultt, setdefaultt] = useState(false);
  //   const [initialDefaultt, setInitialDefaultt] = useState(Boolean);

  // for Billing Address
  // const [isBillingAddressSame, setIsBillingAddressSame] = useState(false);
  const [areaStreet, setAreaStreet] = useState(null);
  const [pincode, setPinCode] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);

  const [userBillingAddress, setUserBillingAddress] = useState({
    Area_Street: "",
    pincode: "",
    city: "",
    state: "",
    country: "India",
    name: "",
    phone: "",
  });

  const handleBillingAddressChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUserBillingAddress({ ...userBillingAddress, [name]: value });
  };

  const SaveAddress = () => {
    // console.log("hi", userAddress);
    if (
      userBillingAddress.Area_Street !== "" &&
      userBillingAddress.type !== "" &&
      userBillingAddress.pincode !== "" &&
      userBillingAddress.city !== "" &&
      userBillingAddress.state !== "" &&
      userBillingAddress.country !== "" &&
      userBillingAddress.name !== "" &&
      userBillingAddress.phone !== ""
    ) {
      dispatch(
        updateUserAddressAndWishlist(
          true,
          "",
          "",
          "",
          "",
          "",
          userBillingAddress
        )
      );
      //   console.log(user.address.length);
      //   setDeliveryAdd(user.address.length);
      setSelectedAddToggle(true);
      setSavedAdd(false);
      // setConfirmDelivNo(userDetail.phone)
      setOrderClick(true);
    } else {
      // window.alert("Provide detail properly")
      toast.error("Provide all details and fill the checklist", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "city") {
      setCity(e.target.value);
      billingAddress.city = e.target.value;
    }
    if (e.target.name === "pincode") {
      setPinCode(e.target.value);
      billingAddress.pincode = e.target.value;
    }
    if (e.target.name === "state") {
      setState(e.target.value);
      billingAddress.state = e.target.value;
    }
    if (e.target.name === "country") {
      setCountry(e.target.value);
      billingAddress.country = e.target.value;
    }
    if (e.target.name === "Area_Street") {
      setAreaStreet(e.target.value);
      billingAddress.Area_Street = e.target.value;
    }
    if (e.target.name === "name") {
      setName(e.target.value);
      billingAddress.name = e.target.value;
    }
    if (e.target.name === "phone") {
      setPhone(e.target.value);
      billingAddress.phone = e.target.value;
    }
  };

  const EditBillingAddress = () => {
    dispatch(
      updateUserAddressAndWishlist(
        true,
        "",
        "",
        "",
        "",
        "",
        "",
        billingAddress,
        billingAddressId
      )
    );
    setEditBillingToggle(!editBillingToggle);
  };

  return userDetails ? (
    <div className="address-cont">
      {userDetails.billing_address &&
      userDetails?.billing_address?.length === 0 ? (
        <>
          <div style={{ color: "black" }}>
            <h6>Please add Billing address first!</h6>
            <div
              className=" flex flex-row gap-2 items-center py-1 px-3 rounded-[5px] border-[1px] border-[#003459] mt-2 cursor-pointer hover:shadow-lg max-w-[240px]"
              onClick={() => {
                setEditToggle(!editToggle);
              }}
            >
              <img src={plusIcon} alt="EHS add address" className="ml-5" />
              <p className=" text-sm text-[#003459] mb-0">
                Add Billing Address
              </p>
            </div>
          </div>
          <AddressPopup popup={editToggle}>
            <div className=" flex flex-col w-full">
              <div className=" flex flex-row-reverse">
                <img
                  src={closeIcon}
                  alt="EHS Close Icon"
                  className="w-[20px] cursor-pointer mr-3"
                  onClick={() => {
                    setEditToggle(!editToggle);
                  }}
                />
                <div> </div>
              </div>
              <div className="address-form">
                <div className="input">
                  <input
                    type="text"
                    name="phone"
                    value={userBillingAddress.phone}
                    onChange={handleBillingAddressChange}
                    // placeholder="Alternate Number (Optional)"
                    placeholder="Phone No."
                  />
                  <input
                    type="text"
                    name="name"
                    value={userBillingAddress.name}
                    onChange={handleBillingAddressChange}
                    placeholder="Name"
                    maxLength={30}
                  />
                </div>
                <div>
                  <textarea
                    className="textarea"
                    id=""
                    cols="30"
                    rows="3"
                    type="text"
                    name="Area_Street"
                    value={userBillingAddress.Area_Street}
                    onChange={handleBillingAddressChange}
                    placeholder="Address(Area and street)"
                    maxLength={70}
                  ></textarea>
                </div>
                <div className="input">
                  <input
                    type="text"
                    value={userBillingAddress.city}
                    onChange={handleBillingAddressChange}
                    name="city"
                    placeholder=" city"
                  />
                  <input
                    type="number"
                    value={userBillingAddress.pincode}
                    onChange={handleBillingAddressChange}
                    name="pincode"
                    placeholder=" pincode"
                  />
                </div>
                <div className="input">
                  {/* <input
                type="text"
                value={state}
                onChange={handleChange}
                name="state"
                placeholder="state"
              /> */}
                  <select
                    value={userBillingAddress.state}
                    id="country-state"
                    name="state"
                    onChange={handleBillingAddressChange}
                  >
                    <option value="" disabled selected hidden>
                      Select State...
                    </option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">
                      Dadra and Nagar Haveli
                    </option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                  <input
                    type="text"
                    value={userBillingAddress.country}
                    onChange={handleBillingAddressChange}
                    name="country"
                    placeholder="country"
                  />
                </div>

                <button
                  className="saved-btn1"
                  onClick={SaveAddress}
                  style={{ width: "60px", padding: "10px" }}
                >
                  Add
                </button>
              </div>
            </div>
          </AddressPopup>
        </>
      ) : (
        userDetails?.billing_address?.map((address, index) => {
          return (
            <>
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row gap-[20px]">
                  <img src={checkBox} alt="Ehs Address" />
                  <p className="text-[#003459] text-base font-medium mb-0">
                    {address.name}
                  </p>
                </div>

                <div className="text-black ml-[38px]">
                  <p className=" text-sm mb-[2px]">
                    {address.Area_Street}, {address.city}
                  </p>
                  <p className=" text-sm mb-[2px]">
                    {address.pincode} {address.state} {address.country}
                  </p>
                  <p className=" text-sm mb-0">{address.phone}</p>
                  <button
                    onClick={() => {
                      setEditBillingToggle(!editBillingToggle);
                      setCity(address.city);
                      setPinCode(address.pincode);
                      setState(address.state);
                      setCountry(address.country);
                      setAreaStreet(address.Area_Street);
                      setName(address.name);
                      setPhone(address.phone);
                      dispatch(
                        editUserBillingAddress({
                          city: address.city,
                          pincode: address.pincode,
                          state: address.state,
                          country: address.country,
                          Area_Street: address.Area_Street,
                          name: address.name,
                          phone: address.phone,
                        })
                      );
                      console.log("hi");
                      setBillingAddressId(address._id);
                    }}
                    className="address-Edit-btn"
                  >
                    <i className="fa fa-edit"></i>
                    <span>Edit</span>
                  </button>
                </div>
              </div>
              <AddressPopup popup={editBillingToggle}>
                <div className="flex flex-col w-full">
                  <div className=" flex flex-row-reverse">
                    <img
                      src={closeIcon}
                      alt="EHS Close Icon"
                      className="w-[20px] cursor-pointer mr-3"
                      onClick={() => {
                        setEditBillingToggle(!editBillingToggle);
                      }}
                    />
                    <div> </div>
                  </div>
                  <div className="address-form">
                    <div className="input">
                      <input
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={handleChange}
                        // placeholder="Alternate Number (Optional)"
                        placeholder="Phone No."
                      />
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        placeholder="Name"
                        maxLength={30}
                      />
                    </div>
                    <div>
                      <textarea
                        className="textarea"
                        id=""
                        cols="30"
                        rows="3"
                        type="text"
                        name="Area_Street"
                        value={areaStreet}
                        onChange={handleChange}
                        placeholder="Address(Area and street)"
                        maxLength={70}
                      ></textarea>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        value={city}
                        onChange={handleChange}
                        name="city"
                        placeholder=" city"
                      />
                      <input
                        type="number"
                        value={pincode}
                        onChange={handleChange}
                        name="pincode"
                        placeholder=" pincode"
                      />
                    </div>
                    <div className="input">
                      {/* <input
                type="text"
                value={state}
                onChange={handleChange}
                name="state"
                placeholder="state"
              /> */}
                      <select
                        value={state}
                        id="country-state"
                        name="state"
                        onChange={handleChange}
                      >
                        <option value="" disabled selected hidden>
                          Select State...
                        </option>
                        <option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                      <input
                        type="text"
                        value={country}
                        onChange={handleChange}
                        name="country"
                        placeholder="country"
                      />
                    </div>

                    <button
                      className="saved-btn1"
                      onClick={EditBillingAddress}
                      style={{ width: "60px", padding: "10px" }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </AddressPopup>
            </>
          );
        })
      )}
    </div>
  ) : null;
};

export default AddBillingAddress;
